body {
    background-color: #1a1a1a;
}

.app {
    width: 100%;
    height: auto;
    position: relative;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1a1a1a;
    color: #FFFFFF;
}

.heading-panel {
    width: 100%;
    height: auto;
    background-color: #131313;
    position: relative;
}

.heading-panel-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
}

.particle-effect {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    opacity: 0.25;
    overflow: hidden;
}

.site-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 512px;
    height: 128px;
}

.heading-text {
    letter-spacing: 0.6rem;
    background: linear-gradient(180deg, rgb(188, 188, 188) 0%, rgb(216, 216, 216) 42%, rgb(127, 127, 127) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    text-align: center;
}

.catalog {
    width: 100%;
    height: auto;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 2rem;
}

.catalog-header {
    width: 90%;
    height: auto;
    display: flex;
    margin: 2rem 0;
    justify-content: center;
    align-items: center;
    border-bottom: 4px solid #ab8644;
    border-radius: 2px;
}

.catalog-header p {
    background: linear-gradient(180deg, rgb(188, 188, 188) 0%, rgb(216, 216, 216) 42%, rgb(127, 127, 127) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-size: 3rem;
    margin: 0;
    padding: 0;
}

.catalog-item {
    width: 500px;
    height: 600px;
    border-radius: 0.5rem;
    background-color: #131313;
    display: flex;
    flex-direction: column;
    transition: all 0.25s ease-in-out;
    box-shadow: 10px 10px 39px -34px rgba(217, 217, 217, 0.35);
}

.catalog-item:hover {
    transform: scale(1.05);
}

.catalog-item-image {
    width: 100%;
    height: 400px;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    object-fit: cover;
    object-position: center;
}

.catalog-item-content {
    width: 100%;
    height: 200px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
}

.catalog-item-desc {
    width: 100%;
    flex-wrap: wrap;
    padding-top: 4px;
}

.catalog-item-desc p {
    padding: 0;
    margin: 0;
}

.catalog-item-title {
    width: 100%;
}

.catalog-item-purchase {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: flex-end;
}

.buy-button {
    background-color: #ff9a05;
    border: none;
    padding: 12px 1.5rem;
    cursor: pointer;
    outline: inherit;
    color: #232323;
    border-radius: 6px;
    transition: all 0.25s ease-in-out;
}

.buy-button:hover {
    background-color: #d77f0d;
}

.footer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    background-color: #131313;
    border-top: 4px solid #ab8644;
    margin-top: 2rem;
}

.footer-title {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-title p {
    margin: 0;
    padding: 0;
    font-size: 1.25rem;
    color: #c9c9c9;
}

.footer-content {
    width: min(16rem, 100%);
    height: auto;
    margin: 2rem 0;

    display: grid;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
    gap: 0.5rem;
}

.social-link {
    width: 4rem;
    height: 4rem;
    border-radius: 8px;
    background: linear-gradient(149deg, rgba(156,112,51,1) 0%, rgba(211,187,113,1) 91%, rgba(226,210,128,1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}

.social-link:hover {
    background: linear-gradient(320deg, rgba(156,112,51,1) 0%, rgba(211,187,113,1) 91%, rgba(226,210,128,1) 100%);
    transform: scale(1.05);
}

.copyright {
    color: #FFFFFF;
}

.copyright p {
    font-size: 1rem;
    margin: 0;
}

.materials {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.material-item {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.material-content {
    width: 400px !important;
    height: 400px !important;;
    display: flex;
    flex-direction: column;
}

.material-content-spacing {
    margin-right: 128px;
}

.material-image {
    width: 400px !important;
    height: 400px !important;
    border-radius: 0.5rem;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
}

.material-content h2 {
    margin: 0;
}

.material-content p {
    padding: 0;
    font-size: 24px;
    margin: 1rem 0 0;
}

.materials-header {
    width: 90%;
    height: auto;
    display: flex;
    margin: 2rem 0;
    justify-content: center;
    align-items: center;
    border-bottom: 4px solid #ab8644;
    border-radius: 2px;
}

.materials-header p {
    background: linear-gradient(180deg, rgb(188, 188, 188) 0%, rgb(216, 216, 216) 42%, rgb(127, 127, 127) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-size: 3rem;
    margin: 0;
    padding: 0;
}

.navbar {
    width: 100%;
    box-sizing: border-box;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    position: fixed;
    top: 0;
    z-index: 100;
    transition: all 0.25s ease-in;
}

.navbar-solid {
    background-color: #232323;
    border-bottom: 3px solid #d5a551;
}

.navbar-transparent {
    background-color: transparent;
    color: #f3f3f3;
}

.navbar-left {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    padding: 0 1rem;
}

.navbar-title {
    margin: 0 0 0 4px;
    color: #d5a551;
}

.navbar-logo {
    width: 50px;
    height: 50px;
}

.navbar-right {
    width: auto;
    height: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.navbar-link {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
    color: #FFFFFF;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
}

.navbar-link:hover {
    color: #d5a551;
}

.navbar-link p {
    margin: 0;
}

/* Media queries */
@media (max-width: 1200px) {
    .catalog {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }

    .catalog-item {
        width: 400px;
        height: 550px;
    }

    .catalog-item-image {
        height: 350px;
    }
}

@media (max-width: 512px) {
    .logo {
        width: 256px;
        height: 64px;
    }

    .material-content-spacing {
        margin-right: 0;
    }

    .material-item {
        justify-content: center;
        align-items: center;
    }

    .material-content {
        justify-content: center;
        align-items: center;
        width: 300px !important;
        height: auto !important;
    }

    .material-content p {
        text-align: center;
    }

    .material-image {
        width: 300px !important;
        height: 300px !important;
    }
}

@media (max-width: 992px) {
    .catalog {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 2rem;
    }

    .catalog-item {
        width: 300px;
        height: auto;
    }

    .catalog-item-image {
        height: 250px;
    }

    .catalog-item-content {
        height: auto;
    }

    .material-content-spacing {
        margin-right: 1rem;
    }

    .material-item {
        justify-content: center;
        align-items: center;
    }

    .material-content {
        justify-content: center;
        align-items: center;
    }

    .material-content p {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .catalog {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 1rem;
    }

    .catalog-item {
        width: 300px;
    }

    .catalog-item-image {
        height: 200px;
    }

    .catalog-item-content {
        height: auto;
    }
}
